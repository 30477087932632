.choice-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  .choice-item {
    flex: 1;
    flex-shrink: 1;
    border-top: 1px solid rgba(31,56,88,.3);
    border-bottom: 1px solid rgba(31,56,88,.3);
    border-left: 1px solid rgba(31,56,88,.3);
    position: relative;
    box-sizing: content-box;
    user-select: none;
    height: 22px;
    padding: 2px 5px;
    color: rgba(0,0,0,.6);
    font-family: PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica,Arial,sans-serif;
    font-size: 12px;
    cursor: pointer;
    background: #fff;
    transition: background .2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .choice-item:hover {
    background: rgba(31,56,88,.04);
  }
  .choice-item:last-child {
    border-right: 1px solid rgba(31,56,88,.3);
    border-radius: 0 3px 3px 0;
  }
  .choice-item:first-child {
    border-radius: 3px 0 0 3px;
  }
  .choice-item-active {
    color: hsla(0,0%,100%,.8);
    background: rgba(31,56,88,.6);
  }
  .choice-item-active:hover {
    color: hsla(0,0%,100%,.8);
    background: rgba(31,56,88,.6);
  }
}

