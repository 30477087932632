.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #f0f0f0;
}

.header-title {
  font-size: 24px;
  color: #333;
  font-weight: bold;
  padding: 0 32px;
}

.right-buttons {
  display: flex;
  padding-right: 32px;
}