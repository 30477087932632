.module-form-item {
  display: flex;
  align-items: center;
  & + & {
    margin-top: 10px;
  }
}

.module-form-item-label-require {
  color: #333;
  font-size: 14px;
  margin-right: 8px;
  &::after {
    display: inline-block;
    margin-right: 4px;
    margin-left: 2px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
  }
}

.tab-container {
  height: 100%;
  :global {
    .ant-tabs-nav {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 0 !important;
      &::before {
        width: 89px;
        text-align: center;
        content: '页面' !important;
        position: relative !important;
        border: none !important;
        font-size: 18px;
      }
    }
  }
}
