.widget-list {
  height: 100%;
  padding-top: 20px;
  background: #fff;
  .widget-list-title {
    padding-left: 24px;
    font-size: 14px;
    line-height: 18px;
    color: #00000073;
  }
  .widget-item {
    height: 40px;
    align-items: center;
    padding-left: 24px;
    margin: 16px;
    font-size: 14px;
    border-radius: 6px;
    cursor: grab;
    display: flex;
    border: 1px solid rgba(187,191,196,.5);
    background: transparent;
    &:hover .widget-item-title div{
      color: #1890ff;
    }
    .widget-item-title {
      display: flex;
      user-select:none;
      .widget-item-cn {
        color: #000000a9;
        margin-right: 8px;
      }
      .widget-item-en {
        color: #00000073;
      }
    }
  }
}