.canvas-container {
  flex: 1;
  height: calc(100vh - 100px);
  overflow: auto;
  flex-shrink: 0;
  padding: 0 20px;
  .content {
    background-color: #fff;
    margin: 20px auto;
    overflow: auto;
  }
}