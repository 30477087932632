.active-container {
  position: relative;
}

.active-line {
  background-color: #3688f3;
  position: absolute;
  z-index: 9999;
}

.hover-line {
  height: 0;
  width: 0;
  border: dashed 1px #3688f3;
}

.active-line-top {
  left: 0;
  right: 0;
  top: 0px;
  height: 2px;
}

.active-line-bottom {
  left: 0;
  right: 0;
  bottom: 0px;
  height: 2px;
}

.active-line-left {
  top: 0;
  bottom: 0;
  left: 0px;
  width: 2px;
}

.active-line-right {
  top: 0;
  bottom: 0;
  right: 0px;
  width: 2px;
}