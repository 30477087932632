.left-container {
  position: relative;
  :global {
    .ant-drawer-left>.ant-drawer-content-wrapper {
      left: 90px;
    }
    .ant-menu-vertical {
      height: 100%;
    }
    .ant-drawer-panel-motion-left-leave-active {
      transform: translateX(-130%);
    }
    .ant-drawer-header-title {
      justify-content: end;
    }
    .ant-drawer-close {
      margin-right: 0;
    }
    .ant-menu-item {
      text-align: center;
    }
  }
}