.props-form {
  width: 300px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
  background: #fff;
  position: relative;
}

.propsform-container {
  margin-bottom: 20px;
  margin-top: 10px;
}

.propsform-title {
  padding-left: 24px;
  font-size: 14px;
  line-height: 18px;
  color: #00000073;
  margin-bottom: 10px;
}

.propsform-item-container {
  padding: 0 24px;
  margin-bottom: 16px;
}

.propsform-item-container:last-child {
  margin-bottom: 0;
}

.propsform-item-title {
  color: #333;
  font-size: 14px;
  line-height: 28px;
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.propsform-item-input {
  width: 100%;
}

.tab-container {
  .ant-tabs-nav {
    border-bottom: 1px solid #f0f0f0;
    &::before {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
    }
  }
  .ant-tabs-nav-wrap {
    padding: 0 20px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    justify-content: center;
  }
}

.picker-input {
  height: 30px;
  cursor: pointer;
  position: relative;
  z-index: 999;
}
.pirker-input-focus {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
  outline: 0
}
.picker-content {
  position: absolute;
  top: 38px;
  left: 0;
}

.picker-placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.picker-color {
  display: flex;
  align-items: center;
  height: 100%;
  .picker-color-block {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}